export function normalizeName(name: string, setter?: (newName: string) => void): string {
    if (isCharNumber(name[0])) {
        const newName = "VOE" + name;
        setter?.(newName);
        return newName;
    }
    return name;
}

function isCharNumber(c: string): boolean {
    return c >= '0' && c <= '9';
}