import { useMemo, useRef, useState } from "react";
import styles from "./app.module.scss";
import cn from 'classnames';
import ProductEntry, { Entry, ExcelResponse } from "./components/ProductEntry/ProductEntry";
import { popup, ButtonResult, ButtonType, updateCart, RemanufacturedChoice, PopupSize } from "ui";
import { RemanChoices, RemanResults } from "ui/src/types";
import { normalizeName } from "./normalize";

type Message = {
    isSuccess: boolean;
    value: string;
}

let lastId = 1;
const emptyEntry = (): Entry => ({
    articleNumber: { value: "", error: null },
    quantity: { value: 1, error: null },
    id: lastId,
    error: null
});

const translations = window.app.preloadState.translation;
const quickOrderPage = window.app.preloadState.quickOrderPage;
const acceptedTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

function App() {
    const [entries, setEntries] = useState<Entry[]>([emptyEntry()]);

    const [backendMessage, setBackendMessage] = useState<Message | null>(null);
    const noErrors = useMemo(() => entries.every(entry => !entry.error && !entry.articleNumber.error && !entry.quantity.error), [entries]);
    const noErrorsAndNotEmpty = useMemo(() => entries.length > 1 && noErrors, [entries, noErrors]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [buttonMessage, setButtonMessage] = useState<string>(translations["quickOrder.selectFromDesktop"]);

    const addRow = () => {
        lastId++;
        setEntries([...entries, emptyEntry()]);
    }

    const changeLabel = () => {
        setButtonMessage(fileInputRef.current?.files?.item(0)?.name ?? translations["quickOrder.selectFromDesktop"]);
    }

    const sendFileToBackend = async () => {

        const files = Array.from(fileInputRef.current?.files ?? []);
        if (files.length !== 1) {
            console.error(translations["quickOrder.uploadOneFile"])
            return;
        }

        const formData = new FormData();
        formData.append('excelFile', files[0]);

        const response = await fetch('/api/quick-order/upload', {
            method: 'POST',
            body: formData,
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });

        if (!response.ok) {
            console.error(await response.text());
            return;
        }

        setButtonMessage(translations["quickOrder.selectFromDesktop"]);
        fileInputRef.current!.value = "";
        setUploadFileMessage("");

        const data: ExcelResponse = await response.json();
        data.quickOrderEntries.forEach(entry => {
            lastId++;
            entry.id = lastId;
            entry.articleNumber.value = normalizeName(entry.articleNumber.value);
        });

        window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Quick Order Upload File', data.quickOrderEntries.length.toString()]);

        setUploadFileMessage(data.excelValidationMessage);

        const lastEntry = entries.pop()!;

        const combinedEntries = combine(entries, data.quickOrderEntries);

        const validationResponse = await fetch('/api/quick-order/validate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(combinedEntries),
        });

        if (!validationResponse.ok) {
            console.error(await validationResponse.text());
            return;
        }

        setEntries([...(await validationResponse.json()), lastEntry]);
    }

    const validateForDuplicates = (entries: Entry[]) => {
        const checked = new Set<string>();
        entries.forEach(entry => {
            entry.articleNumber.error = entry.articleNumber.error
                ? entry.articleNumber.error.replaceAll(translations["quickOrder.articleExistInList"], "")
                : null;
            if (checked.has(entry.articleNumber.value)) {
                entry.articleNumber.error ??= "";
                entry.articleNumber.error += translations["quickOrder.articleExistInList"];
            } else {
                checked.add(entry.articleNumber.value);
            }
        });
    }

    const changeEntry = (entry: Entry) => {
        const newEntries = entries.map(e => e.id === entry.id ? entry : e);
        validateForDuplicates(newEntries);
        setEntries(newEntries);
    }

    const removeEntry = (id: number) => {
        const newEntries = entries.filter(e => e.id !== id);
        validateForDuplicates(newEntries);
        setEntries(newEntries);
    }

    const clearAll = async () => {
        const result = await popup(
            translations["quickOrder.popUpHeader"],
            translations["quickOrder.popUpContent"],
            [
                { type: ButtonType.Primary, label: translations["quickOrder.popUpConfirmationButton"], result: ButtonResult.Yes },
                { type: ButtonType.Outlined, label: translations["quickOrder.popUpCancellationButton"], result: ButtonResult.Cancel }
            ]
        );

        if (result !== ButtonResult.Yes)
            return;

        window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Quick Order Clear All', (entries.length - 1).toString()]);

        lastId += 1;
        setEntries([emptyEntry()]);
    };

    const [uploadFileMessage, setUploadFileMessage] = useState<string>("");

    const dropHandler = (e: React.DragEvent) => {
        e.preventDefault();
        e.currentTarget.classList.remove(styles.dragOver);

        fileInputRef.current!.value = "";
        setButtonMessage(translations["quickOrder.selectFromDesktop"]);

        var files = e.dataTransfer.files;

        if (files.length !== 1) {
            setUploadFileMessage(translations["quickOrder.uploadOneFile"]);
            return;
        } 

        if (!acceptedTypes.includes(e.dataTransfer.files[0].type)) {
            setUploadFileMessage(translations["quickOrder.wrongExtensionUploadExcelFile"]);
            return;
        }

        setUploadFileMessage("");
        fileInputRef.current!.files = e.dataTransfer.files;
        changeLabel();
    }

    const dragOverHandler = (e: React.DragEvent) => {
        e.currentTarget.classList.add(styles.dragOver);
        e.preventDefault();
    }

    const dragEndHandler = (e: React.DragEvent) => {
        e.preventDefault();
        e.currentTarget.classList.remove(styles.dragOver);
    };

    const addItemsToCart = async (items: Entry[]) => {
        const response = await fetch('/api/quick-order/add', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Swecon-Current-Language': window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(items),
        });

        if (response.status >= 500) {
            console.error(await response.text());
            return;
        } else if (response.ok) {
            window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Quick Order Add To Cart', entries.length.toString()]);
            lastId += 1;
            setEntries([emptyEntry()]);
            updateCart();
        }
        setBackendMessage({ isSuccess: response.ok, value: await response.json() });
        setTimeout(() => setBackendMessage(null), 2000);
    }

    const addToCart = async () => {

        const toSend = [...entries];
        toSend.pop();


        const response = await fetch('/api/pdp/reman-item-details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Swecon-Current-Language': window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(toSend.map(item => item.articleNumber.value)),
        });

        if (!response.ok) {
            console.error(await response.text());
            return;
        }
        const withRemanItems: RemanChoices = await response.json();
        if (Object.keys(withRemanItems).length === 0) {
            await addItemsToCart(toSend);
            return;
        }
        let result: RemanResults = {};

        const changeResult = (results: RemanResults) => {
            result = results;
        }

        const quantityOverride = Object.fromEntries(toSend.map(item => ([
            item.articleNumber.value,
            item.quantity.value
        ])));

        const popUpResult = await popup(
            translations["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={Object.keys(withRemanItems)} quantityOverride={quantityOverride} />,
            [
                { label: translations["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translations["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge,
            "noPaddingsPopUp"
        );

        if (popUpResult !== ButtonResult.Ok) {
            return;
        }

        const toSendWithReman = toSend
            .flatMap(item => {
                if (!(item.articleNumber.value in withRemanItems)) {
                    return [item];
                }
                const data = result[item.articleNumber.value];
                if (!data.selectedReman) {
                    return [{
                        ...item,
                        quantity: {
                            error: null,
                            value: data.sparePartQuantity
                        }
                    }];
                }

                const details = withRemanItems[item.articleNumber.value]!;
                return [{
                    id: 0,
                    articleNumber: {
                        error: null,
                        value: details.remanPartDetails.code
                    },
                    quantity: {
                        error: null,
                        value: data.remanQuantity
                    },
                    error: null
                }, {
                    id: 0,
                    articleNumber: {
                        error: null,
                        value: details.depositDetails.code
                    },
                    quantity: {
                        error: null,
                        value: data.remanQuantity
                    },
                    error: null
                }];
            });

        await addItemsToCart(toSendWithReman);
    }

    const OnDownloadTemplate = () => {
        window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Quick Order Download Template']);
    }

    return <div className="page">
        <div className={styles.quickOrder}>
            <div className="pageHeader">
                <h1>{translations["quickOrder.quickOrder"]}</h1>
            </div>
            <div className={styles.productsTable}>
                <div className={cn(styles.backendMessage, backendMessage?.isSuccess ? styles.successfulMessage : styles.errorMessage)}>
                    {backendMessage?.value}
                </div>
                <div className={styles.tableHeader}>
                    <div className={styles.firstColumn}>{translations["quickOrder.articleNumber"]}</div>
                    <div className={styles.secondColumn}>{translations["quickOrder.quantity"]}</div>
                    <div className={styles.actionColumn}></div>
                </div>
                <div className={ styles.tableBody}>
                    {entries.map((entry, index) => (
                        <ProductEntry
                            key={entry.id}
                            entry={entry}
                            articleNoPlaceholder={translations["quickOrder.articleNumber"]}
                            onDataChange={changeEntry}
                            onRemove={removeEntry.bind(null, entry.id)}
                            onNameChange={index == (entries.length - 1) ? addRow : null}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.buttons} >
                <button disabled={!(entries.length > 1)} className={cn(styles.clearAllButton, "btn btn--outlined")} onClick={clearAll}>
                    {translations["quickOrder.clearAll"]}
                </button>
                <button onClick={addToCart} disabled={!noErrorsAndNotEmpty} className={cn(styles.addToBasketButton, "btn btn--primary")}>
                    {translations["quickOrder.addToBasket"]}
                </button>
            </div>
        </div>
        <div className={styles.uploadTemplate}>
            <div className="pageHeader">
                <h1>{translations["quickOrder.uploadTemplate"]}</h1>
            </div>
            {!!quickOrderPage.excelUploadNote && <div className={styles.excelUploadNote} dangerouslySetInnerHTML={{ __html: quickOrderPage.excelUploadNote }}></div>}
            <div className={styles.uploadFileMessage}>{uploadFileMessage}</div>
            <div className={styles.dragAndDropArea}
                onDrop={(e) => dropHandler(e)}
                onDragOver={(e) => dragOverHandler(e)}
                onDragEnd={(e) => dragEndHandler(e)}
                onDragLeave={(e) => dragEndHandler(e)}
                onDragExit={(e) => dragEndHandler(e)}>
                <div id="drop_zone" style={{ opacity: !noErrors ? 0.3 : 1 }}>
                    {translations["quickOrder.dragAndDropExcelFileLabel"]}
                </div>
                <input ref={fileInputRef} onChange={() => changeLabel()} type="file" name="ExcelFile" id="excelInput" accept={acceptedTypes.join(',')}/>
                <button disabled={!noErrors} className={styles.dashedButton} onClick={() => noErrors && fileInputRef.current?.click()}>{buttonMessage}</button>
            </div>

            <div className={styles.buttons}>
                <a href={'/api/quick-order/template'} onClick={OnDownloadTemplate} className={cn(styles.downloadTemplateButton, "btn btn--outlined", !quickOrderPage.isTemplateAvailable && styles.disabled)}>
                    {translations["quickOrder.downloadTemplate"]}
                </a>
                <button disabled={!noErrors || (fileInputRef.current?.files?.length ?? 0) != 1} onClick={sendFileToBackend} className={cn(styles.uploadtButton, "btn btn--primary")}>
                    {translations["quickOrder.upload"]}
                </button>
            </div>
        </div>
  </div>
}

export default App;

const combine = (existingEntries: Entry[], newEntries: Entry[]): Entry[] => {
    const entryDictionary = new Map<string, number>();
    existingEntries.forEach((entry, index) => {
        if (!entryDictionary.has(entry.articleNumber.value))
            entryDictionary.set(entry.articleNumber.value, index)
    });
    const entriesToCombine = newEntries.filter(entry => entryDictionary.has(entry.articleNumber.value));
    const entriesToAdd = newEntries.filter(entry => !entryDictionary.has(entry.articleNumber.value));

    entriesToCombine.forEach(entry => {
        const index = entryDictionary.get(entry.articleNumber.value)!;
        lastId++;
        existingEntries[index].id = lastId;
        existingEntries[index].quantity.value += entry.quantity.value;
    })

    return [...existingEntries, ...entriesToAdd];
}